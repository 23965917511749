import { CountUp } from 'countup.js';
import domready from 'domready'

class profileSlider {
  constructor(elem) {
    this.observed_changes = this.observed_changes.bind(this);
    this.slider = elem;
    this.items_container = this.slider.querySelector('.items');
    this.elm_prev = this.slider.querySelector('.slide-nav.prev');
    this.elm_next = this.slider.querySelector('.slide-nav.next');

    const observer = new IntersectionObserver(
      this.observed_changes,
      {
        root: this.items_container,
        threshold: 0
      }
    );

    for (let item of Array.from(elem.querySelectorAll('.items .card'))) {
      observer.observe(item);
    }

    if (this.elm_prev) {
      this.elm_prev.addEventListener('click', e => {
        if (e.target.classList.contains('inactive')) { return; }
        return this.prev();
      });
    }

    if (this.elm_next) {
      this.elm_next.addEventListener('click', e => {
        if (e.target.classList.contains('inactive')) { return; }
        return this.next();
      });
    }
  }

  observed_changes(entries, observer) {
    for (let entry of Array.from(entries)) {
      const parentElem = entry.target.parentElement;
      if (!entry.isIntersecting) {
        if ((entry.boundingClientRect.x - entry.rootBounds.x) <= 0) {
          parentElem.dataset.position = 'prev';
        } else {
          parentElem.dataset.position = 'next';
        }
      } else {
        delete parentElem.dataset.position;
      }
    }

    if (this.slider.querySelector('[data-position="prev"]')) {
      this.elm_prev.classList.remove('inactive');
    } else {
      this.elm_prev.classList.add('inactive');
    }

    if (this.slider.querySelector('[data-position="next"]')) {
      return this.elm_next.classList.remove('inactive');
    } else {
      return this.elm_next.classList.add('inactive');
    }
  }

  prev() {
    const prev_elm = Array.from(this.items_container.querySelectorAll('[data-position="prev"] .card')).pop();
    if (prev_elm) {
      return prev_elm.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    }
  }

  next() {
    const next_elm = this.items_container.querySelector('[data-position="next"] .card');
    if (next_elm) {
      return next_elm.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    }
  }
}

domready(() => {
  for (let element of document.querySelectorAll('.profile-slider')) {
    element.slider = new profileSlider(element)
  }
})

document.addEventListener('scroll', function(e) {
  if (e.target !== document) { return; }

  const elements = document.querySelectorAll('section.sector-support .cards a.card span:not(.counted)');

  for (let elem of elements) {
    const bnds = elem.getBoundingClientRect();

    if ((bnds.top >= 0) && (bnds.left >= 0) &&
       (bnds.right <= (window.innerWidth || document.documentElement.clientWidth)) &&
       (bnds.bottom <= (window.innerHeight || document.documentElement.clientHeight))
    ) {
      elem.classList.add('counted');

      const count = new CountUp(
        elem,
        elem.dataset.countup,
        { duration: 2.5, useEasing: true, useGrouping: true, separator: ',', decimal: '.' }
      );
      count.start();
    };
  }
});
